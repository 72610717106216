import dayjs from 'dayjs'

import { DATE_FORMAT } from './constants'

const formatUtcDate = (
  date?: string | null,
  format: string = DATE_FORMAT,
): string => {
  if (!date) return ''

  let parsedDate = dayjs.utc(date)

  // If date is invalid,extracting first 8 characters and creating a new Date
  if (!parsedDate.isValid() && date.length >= 8) {
    const extractedDate = date.substring(0, 8)
    const formattedDate = `${extractedDate.substring(
      0,
      4,
    )}-${extractedDate.substring(4, 6)}-${extractedDate.substring(6, 8)}`
    parsedDate = dayjs.utc(formattedDate, 'YYYY-MM-DD')
  }

  return parsedDate.isValid() ? parsedDate.format(format) : ''
}

export default formatUtcDate
