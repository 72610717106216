import React, { useMemo } from 'react'

import {
  Box,
  Flex,
  Skeleton,
  SkeletonProps,
  Text,
  VStack,
} from '@rhythm/components'

import { TransmissionReportSignature } from '../../../../../lib/api'
import buildUserName from '../../../../../utils/buildUserName'

import '@fontsource/bad-script'

export interface SignatureProps {
  signature?: TransmissionReportSignature
  signatureIsCurrentUser?: boolean
}

const Signature = ({
  signature,
  signatureIsCurrentUser,
}: SignatureProps): React.ReactElement => {
  const { user } = signature || {}
  let cred = user?.credentials?.join(', ')
  if (cred) {
    cred = ', ' + cred
  }

  const signatureDate = signature?.signDate?.replace(/-/g, '/')
  const Skel = useMemo(() => {
    const Component = (props: SkeletonProps) => (
      <Skeleton {...props} isLoaded={signature !== undefined} />
    )

    // Set a display name for debugging purposes
    Component.displayName = 'Skel'

    return Component
  }, [signature])

  return (
    <VStack
      alignItems="flex-start"
      spacing="sm"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="neutral.400"
      borderRadius="md"
      p="2xl"
      w="100%"
    >
      <Skel h="lg">
        <Text fontSize="4xl" fontFamily="Bad Script">
          {`${user?.given} ${user?.family}${cred ? cred : ''}`}
        </Text>
      </Skel>
      <Box w="100%">
        <Flex justify="space-between" align="center" w="100%">
          <Text color="neutral.black" fontWeight="bold">
            {buildUserName(user)}
            {signatureIsCurrentUser && ' (you)'}
          </Text>
          <Text color="neutral.black">{`Date: ` + signatureDate}</Text>
        </Flex>
      </Box>
    </VStack>
  )
}

export default Signature
