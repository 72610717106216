import React from 'react'

import {
  Box,
  Card,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@rhythm/components'

import {
  TransmissionReportDtoFilesUploadStatus,
  TransmissionReportSignatureStatusEnum,
} from '../../../../../lib/api'
import {
  OrderStatus,
  TramssionReportColor,
  TransmissionStatus,
} from '../../../../../types'
import formatDate from '../../../../../utils/formatDate'
import getOrderStatusName from '../../../../../utils/getOrderStatusName'
import getReportName from '../../../../../utils/getReportName'

import '../../../TransmissionReportPage/components/BillingCard/BillingCardStyle.css'

const getStatusColor = (status?: TramssionReportColor | TransmissionStatus) => {
  switch (status) {
    case TramssionReportColor.RED:
      return 'feedback.error'
    case TramssionReportColor.YELLOW:
      return 'feedback.warning'
    default:
      return 'transparent'
  }
}

export interface ReportCardProps {
  children?: React.ReactNode
  status?: TramssionReportColor | TransmissionStatus
  signatureStatus?: TransmissionReportSignatureStatusEnum
  orderStatus?: string
  showOrderStatus?: boolean
  reportName?: string
  billingDate?: string
  reportCreatedDate?: string
  editedUser?: string
  editedDate?: string
  transmissionType?: string
  filesUploadStatus?: FilesUploadStatus
}

interface Status {
  fileName: string
  status: TransmissionReportDtoFilesUploadStatus
}

export interface FilesUploadStatus {
  fileStatus: Status[]
}

// Helper function to render transmission details
const renderTransmissionDetails = (
  transmissionType: string | undefined,
  filesUploadStatus: FilesUploadStatus | undefined,
  reportCreatedDate: string | undefined,
  billingDate: string | undefined,
  signatureStatus: string | undefined,
  editedUser: string | undefined,
  editedDate: string | undefined,
) => {
  const isManualOrInClinic =
    transmissionType && ['in-clinic', 'manual'].includes(transmissionType)

  const isFilesAvailable =
    isManualOrInClinic && filesUploadStatus?.fileStatus?.length

  return (
    <Box>
      {isFilesAvailable && (
        <HStack spacing="md" mb={2}>
          <Flex gap={1} flexDirection={'column'}>
            {filesUploadStatus?.fileStatus.map(file => (
              <Text
                key={file.fileName}
                variant="secondary"
                wordBreak="break-all"
                style={{ width: '100%', paddingRight: '10px' }}
              >
                {file.fileName}
              </Text>
            ))}
          </Flex>
        </HStack>
      )}
      <Flex gap={1} flexDirection={'column'}>
        <Text variant="secondary">
          {isManualOrInClinic ? 'Uploaded on ' : 'Transmitted on '}
          {formatDate(reportCreatedDate)}
        </Text>
        {billingDate && (
          <Text variant="secondary">
            Date of Service: {formatDate(billingDate)}
          </Text>
        )}
        {signatureStatus === 'NON_PRACTITIONER_SIGNED' && (
          <Text variant="secondary"> - Awaiting Physician Signature</Text>
        )}
        {editedUser && editedUser != 'null' && (
          <Text variant="secondary">
            Edited on {formatDate(editedDate)} by {editedUser}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

const ReportCard = ({
  children,
  reportName,
  billingDate,
  reportCreatedDate,
  status,
  signatureStatus,
  orderStatus,
  showOrderStatus,
  editedUser,
  editedDate,
  transmissionType,
  filesUploadStatus,
}: ReportCardProps): React.ReactElement => {
  return (
    <Card px="xl" py="20px" style={{ cursor: 'unset' }}>
      <Flex>
        <Box
          width="4px"
          borderRadius="md"
          bg={getStatusColor(status)}
          mr="2xl"
        />
        <Stack flex={4} spacing="sm">
          <HStack spacing="md" style={{ paddingBottom: '4px' }}>
            <Heading variant="h5">
              {reportName ?? getReportName(status)}
            </Heading>
            {showOrderStatus && (
              <>
                <Icon icon="chevron-right" boxSize="sm" />
                <label>
                  {getOrderStatusName((orderStatus ?? '') as OrderStatus)}
                </label>
              </>
            )}
          </HStack>
          {renderTransmissionDetails(
            transmissionType,
            filesUploadStatus,
            reportCreatedDate,
            billingDate,
            signatureStatus,
            editedUser,
            editedDate,
          )}
        </Stack>
        <Flex flex={1}>{children}</Flex>
      </Flex>
    </Card>
  )
}

export default ReportCard
